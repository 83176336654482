// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import { Popover } from 'bootstrap';
import CookiesEuBanner from 'cookies-eu-banner'
import "@fortawesome/fontawesome-free/js/all.min";
import "simple-datatables/dist/style.css"

import gtag from 'src/analytics';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function(event) {
    gtag('config', 'G-084MDF1MC9', {
        page_location: event.data.url,
        page_path: event.srcElement.location.pathname,
        page_title: event.srcElement.title
    });

    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    const popoverList = popoverTriggerList.map( (popoverTriggerEl) => new Popover(popoverTriggerEl) )

    new CookiesEuBanner( () => {}, true);
})